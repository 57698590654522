<template>
    <section>
        <a class="mb-1" href="#" @click.prevent="$emit('view', {component: 'ListPengembalian', pengembalian: null})">Kembali</a>
        <b-card>
            <span class="text-secondary">{{ item.no }}</span>
            <h3 class="mb-2">Detail Pengembalian</h3>
            <b-row>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Sales">
                        <strong>{{ item.sales ? item.sales.nama_lengkap : item.id_sales }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Tanggal Request Pengembalian">
                        <strong>{{ humanDate(item.tanggal) }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Keterangan">
                        <strong>{{ item.keterangan }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Tujuan Pengembalian">
                        <strong>{{ item.gudang ? item.gudang.nama_gudang : item.id_gudang }}</strong>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <h3>Rincian Barang <span v-if="item.rincian.length > 0">({{ item.rincian.length }})</span></h3>
        <b-card>
            <b-table responsive striped bordered :fields="fields" :items="item.rincian">
                <template #cell(no)="{index}">{{++index}}</template>
                <template #cell(nama)="{item}">
                    <span v-if="item.barang"> {{ item.barang.nama }} </span>
                    <strong v-else>
                        <i class="text-danger">Barang tidak ditemukan</i>
                    </strong>
                </template>
                <template #cell(status)="{item}">
                    <feather-icon size="24" v-if="item.status && item.status.approve" icon="CheckCircleIcon" class="text-success"></feather-icon>
                    <feather-icon size="24" v-else icon="XCircleIcon" class="text-danger"></feather-icon>
                </template>
                <template #cell(satuan)="{item}">
                    <span v-if="item.barang"> {{ item.barang.satuan ? item.barang.satuan.satuan : '-' }} </span>
                    <strong v-else>
                        <i class="text-danger">Barang tidak ditemukan</i>
                    </strong>
                </template>
                <template #cell(action)="{index, item}">
                    <feather-icon v-if="isSales && myGudang.id && item.status && !item.status.approve" icon="MinusCircleIcon" size="24" class="text-danger" @click="removeRincian(item, index)"></feather-icon>
                    <feather-icon v-else icon="SlashIcon" size="24" class="text-secondary"></feather-icon>
                </template>
            </b-table>
        </b-card>
    </section>
</template>
<script>
import {BCard, BRow, BCol, BFormGroup, BTable} from 'bootstrap-vue'
export default {
    props: ['pengembalian'],
    data: () => ({
        item: {},
        fields: [
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Barang'},
            {key: 'qty', label: 'Stok Pengembalian'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'status', label: 'Disetujui'},
            {key: 'action', label: ''}
        ],
        confirmOption: {
            title: 'Anda yakin?',
            text: `Rincian barang ini akan dihapus`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        removeRincian(item, index) {
            this.$swal(this.confirmOption)
            .then(async res => {
                if(res.value) {
                    await this.$store.dispatch('pengembalianbarang/saveRincian', [{
                        id: item.id,
                        fungsi: 1
                    }])

                    // remove from array
                    this.item.rincian.splice(index, 1)
                }
            })
        }
    },
    components: {
        BCard, BRow, BCol, BFormGroup, BTable
    },
    watch: {
        pengembalian(item) {
            if(item) {
                Object.assign(this.item, item)
            }
        }
    },
    created() {
        if(this.pengembalian) {
            this.item = this.pengembalian
        }
    }
}
</script>