<template>
  <b-modal id="retur-modal" title="Form Request Pengembalian">
    <b-form-group label="Jumlah Item">
      <b-form-input disabled :value="items.length"></b-form-input>
    </b-form-group>
    <b-form-group label="Tanggal">
      <b-form-input type="date" v-model="form.tanggal"></b-form-input>
    </b-form-group>
    <b-form-group label="Pilih Gudang">
      <b-form-select v-model="form.id_gudang" :options="id_gudang"></b-form-select>
    </b-form-group>
    <b-form-group label="Keterangan (optional)">
      <b-form-textarea v-model="form.keterangan"></b-form-textarea>
    </b-form-group>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click.prevent="submit"
          >Request Pengembalian</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
export default {
  props: ["items"],
  //   props: ["id_gudang"],
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    flatPickr,
  },
  data: () => ({
    form: {
      tanggal: null,
      keterangan: null,
      id_sales: null,
      id_gudang_m: null,
      id_gudang: null,
    },
    id_gudang: [],
  }),
  async mounted() {
    this.resetForm()
    this.getDataGudang1();
  },
  methods: {
    resetForm() {
      this.form.id_sales = null
      this.form.tanggal = this.getCurrentDate()
      this.form.id_gudang_m = null
      this.form.id_gudang = null
      this.form.keterangan = null
    },
    submit() {
      if (!this.form.tanggal || !this.form.id_gudang) {
        this.displayError({
          message: "Harap pilih tanggal request!",
        });
        return false;
      }

      this.form.id_sales = this.user.karyawan.id;
      this.form.id_gudang_m = this.myGudang.id;

      // set payload rincian
      const payloads = [];
      this.items.map((barang) => {
        payloads.push({
          id_pengembalian: null,
          id_barang: barang.id_barang,
          qty: barang.jumlah_retur,
        });
      });
      this.$emit("submit", {
        pengembalian: this.form,
        rincian: payloads,
        done: () => {
          this.resetForm()
        }
      });
    },
    async getDataGudang1() {
      await this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
  },
};
</script>
