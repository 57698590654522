<template>
    <b-tabs :vertical="isVerticalDevice" pills>
        <b-tab ref="stok-tab" title="Stok Mobil" :active.sync="stokTab">
            <stok-mobil @submitRetur="afterSubmitRetur"></stok-mobil>
        </b-tab>
        <b-tab ref="retur-tab" title="Stok Retur" :active.sync="returTab">
            <retur-konsumen :active="returTab" />
        </b-tab>
        <b-tab ref="pengembalian-tab" title="Data Pengembaliaan" :active.sync="pengembalianTab">
            <pengembalian :active="pengembalianTab" />
        </b-tab>
    </b-tabs>
</template>
<script>
import StokMobil from './components/Stok.vue'
import Pengembalian from './components/Pengembalian.vue'
import ReturKonsumen from './components/ReturKonsumen.vue'
import {BTabs, BTab} from 'bootstrap-vue'
export default {
    components: {
        StokMobil,
        Pengembalian,
        BTabs, BTab, ReturKonsumen
    },
    data: () => ({
        isVerticalDevice: null,
        stokTab: false,
        returTab: false,
        pengembalianTab: false
    }),
    methods: {
        afterSubmitRetur() {
            this.$bvModal.hide('retur-modal')
            window.location.href = `${window.location.href}?pengembalian=1`
        }
    },
    mounted() {
        if(this.$route.query.pengembalian) {
            this.stokTab = false
            this.pengembalianTab = true
        }
        else {
            this.stokTab = true
            this.pengembalianTab = false
        }
    }
}
</script>